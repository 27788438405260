<template>
  <!--  手机端显示菜谱-->
  <div>
    <!--  选择日期-->
    <div style="margin-top: 5%;margin-left: 5%">
      <el-tag>选择日期</el-tag>
      <el-date-picker
          v-model="date_food"
          type="date"
          placeholder="选择日期"
          format="YYYY-MM-DD"
          @change="changeDate"
          style="margin-right: 50px"
          size="small"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </div>
  <!--  早餐-->
    <el-divider style="margin-top: 10%"> 早餐 </el-divider>
    <div class="food_div1">
      <div class="food_div2">
        <el-tag effect="dark" style="margin-right: 5%;margin-top: 2%">主食</el-tag>
        <div v-for="(item, index) in bri_main_food" :key="index" class="food_div3">
          <el-tag effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag effect="dark" style="margin-right: 5%;margin-top: 2%">副食</el-tag>
        <div v-for="(item, index) in bri_non_food" :key="index" class="food_div3">
          <el-tag effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag effect="dark" style="margin-right: 5%;margin-top: 2%">主菜</el-tag>
        <div v-for="(item, index) in bri_main_vage" :key="index" class="food_div3">
          <el-tag effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag effect="dark" style="margin-right: 5%;margin-top: 2%">粥品</el-tag>
        <div v-for="(item, index) in bri_congee" :key="index" class="food_div3">
          <el-tag effect="dark">{{ item }}</el-tag>
        </div>
      </div>
    </div>
    <!--  午餐-->
    <el-divider style="margin-top: 10%"> 午餐 </el-divider>
    <div class="food_div1">
      <div class="food_div2">
        <el-tag type="success" effect="dark" style="margin-right: 5%;margin-top: 2%">主食</el-tag>
        <div v-for="(item, index) in lunch_main_food" :key="index" class="food_div3">
          <el-tag type="success" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="success" effect="dark" style="margin-right: 5%;margin-top: 2%">主菜</el-tag>
        <div v-for="(item, index) in lunch_main_vage" :key="index" class="food_div3">
          <el-tag type="success" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="success" effect="dark" style="margin-right: 5%;margin-top: 2%">汤品</el-tag>
        <div v-for="(item, index) in lunch_hot_water" :key="index" class="food_div3">
          <el-tag type="success" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="success" effect="dark" style="margin-right: 5%;margin-top: 2%">时蔬</el-tag>
        <div v-for="(item, index) in lunch_fruits" :key="index" class="food_div3">
          <el-tag type="success" effect="dark">{{ item }}</el-tag>
        </div>
      </div>

    </div>
    <!--  晚餐-->
    <el-divider style="margin-top: 10%"> 晚餐 </el-divider>
    <div class="food_div1">
      <div class="food_div2">
        <el-tag type="warning" effect="dark" style="margin-right: 5%;margin-top: 2%">主食</el-tag>
        <div v-for="(item, index) in supper_main_food" :key="index" class="food_div3">
          <el-tag type="warning" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="warning" effect="dark" style="margin-right: 5%;margin-top: 2%">副食</el-tag>
        <div v-for="(item, index) in supper_non_food" :key="index" class="food_div3">
          <el-tag type="warning" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="warning" effect="dark" style="margin-right: 5%;margin-top: 2%">主菜</el-tag>
        <div v-for="(item, index) in supper_main_vage" :key="index" class="food_div3">
          <el-tag type="warning" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="warning" effect="dark" style="margin-right: 5%;margin-top: 2%">粥品</el-tag>
        <div v-for="(item, index) in supper_congee" :key="index"  class="food_div3">
          <el-tag type="warning" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
      <div class="food_div2">
        <el-tag type="warning" effect="dark" style="margin-right: 5%;margin-top: 2%">汤品</el-tag>
        <div v-for="(item, index) in supper_hot_water" :key="index" class="food_div3">
          <el-tag type="warning" effect="dark">{{ item }}</el-tag>
        </div>
      </div>

    </div>
    <!--  小菜-->
    <el-divider style="margin-top: 10%"> 小菜 </el-divider>
    <div class="food_div1">
      <div class="food_div2">
        <el-tag type="danger" effect="dark" style="margin-right: 5%;margin-top: 2%">小菜</el-tag>
        <div v-for="(item, index) in little_food" :key="index" class="food_div3">
          <el-tag type="danger" effect="dark">{{ item }}</el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";
import {ElMessage} from "element-plus";

export default {
  name: "mobile_show_food",
  data(){
    return{
      date_food: '',
      bri_main_food: [],
      bri_non_food: [],
      bri_main_vage: [],
      bri_congee: [],
      lunch_main_food: [],
      lunch_main_vage: [],
      lunch_hot_water: [],
      lunch_fruits: [],
      supper_main_food: [],
      supper_non_food: [],
      supper_congee: [],
      supper_main_vage: [],
      supper_hot_water: [],
      little_food: []
    }
  },
  created() {
    if (!this.$route.params.key){
      ElMessage.error('非法访问!')
    }else {
      this.date_food = setNowDate(new Date());
      this.getDayFood();
    }

  },
  methods:{
    getDayFood(){
      this.bri_main_food = [];
      this.bri_non_food = [];
      this.bri_main_vage = [];
      this.bri_congee = [];
      this.lunch_main_food = [];
      this.lunch_main_vage = [];
      this.lunch_hot_water = [];
      this.lunch_fruits = [];
      this.supper_main_food = [];
      this.supper_non_food = [];
      this.supper_congee = [];
      this.supper_main_vage = [];
      this.supper_hot_water = [];
      this.little_food = [];
      this.axios.post('/imdDayFood/getDayFoodForMobile', (response) => {
        for (let i = 0; i < response.obj[0].length; i++) {
          if (response.obj[0][i].foodType === '主食') {
            let foods = response.obj[0][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.bri_main_food.push(foods[y]);
            }
          }
          if (response.obj[0][i].foodType === '主菜') {
            let foods = response.obj[0][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.bri_main_vage.push(foods[y]);
            }
          }
          if (response.obj[0][i].foodType === '粥品') {
            let foods = response.obj[0][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.bri_congee.push(foods[y]);
            }
          }
          if (response.obj[0][i].foodType === '副食') {
            let foods = response.obj[0][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.bri_non_food.push(foods[y]);
            }

          }
        }
        for (let i = 0; i < response.obj[1].length; i++) {
          if (response.obj[1][i].foodType === '主食') {
            let foods = response.obj[1][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.lunch_main_food.push(foods[y]);
            }
          }
          if (response.obj[1][i].foodType === '主菜') {
            let foods = response.obj[1][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.lunch_main_vage.push(foods[y]);
            }
          }
          if (response.obj[1][i].foodType === '汤品') {
            let foods = response.obj[1][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.lunch_hot_water.push(foods[y]);
            }
          }
          if (response.obj[1][i].foodType === '时蔬') {
            let foods = response.obj[1][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.lunch_fruits.push(foods[y]);
            }

          }
        }
        for (let i = 0; i < response.obj[2].length; i++) {
          if (response.obj[2][i].foodType === '主食') {
            let foods = response.obj[2][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.supper_main_food.push(foods[y]);
            }
          }
          if (response.obj[2][i].foodType === '主菜') {
            let foods = response.obj[2][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.supper_main_vage.push(foods[y]);
            }
          }
          if (response.obj[2][i].foodType === '粥品') {
            let foods = response.obj[2][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.supper_congee.push(foods[y]);
            }
          }
          if (response.obj[2][i].foodType === '副食') {
            let foods = response.obj[2][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.supper_non_food.push(foods[y]);
            }

          }
          if (response.obj[2][i].foodType === '主菜') {
            let foods = response.obj[2][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.supper_main_vage.push(foods[y]);
            }

          }
        }
        for (let i = 0; i < response.obj[3].length; i++) {
          if (response.obj[3][i].foodType === '小菜') {
            let foods = response.obj[3][i].foodName.split(",");
            for (let y = 0; y < foods.length; y++) {
              this.little_food.push(foods[y]);
            }
          }
        }
      },{
        food_time: this.date_food
      })
    },
    changeDate(){
      this.getDayFood();
    }
  }
}
</script>

<style scoped>
.food_div1 {
  margin-left: 5%;
  margin-top: 5%
}
.food_div2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%
}
.food_div3{
  margin-right: 2%;
  margin-top: 2%
}
</style>